import * as React from "react"

import Layout from "../components/common/Layout"
import HomePage from "../components/pages/home"

const IndexPage = () => (
  <Layout>
    <HomePage />
  </Layout>
)

export default IndexPage
